/* react-alerts css starts */
div#__react-alert__>div>div>div {
	background-color: #fff !important;
	color: #000 !important;
	width: auto !important;
	min-width: 300px;
	box-shadow: rgb(102 78 86 / 10%) 0px 2px 2px 2px;
}

div#__react-alert__>div>div>div>span:first-letter {
	text-transform: capitalize;
}

#__react-alert__ div div div {
	text-transform: initial !important;
	font: inherit !important;
}

/* react-alerts css ends */

.alert-div {
	display: flex;
	gap: 10px;
}

.copy-btn {
	background-color: #005DFF;
	border: none;
	color: white;
	padding: 5px 15px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	border-radius: 8px;
	margin-left: 5px;
}


.modal-body {
	padding: 20px;
}

.modal-body-filter {
	width: 1246px;
	height: 145px;
	left: 2412px;
	top: 1796px;
}

.modal-title {
	font-size: 24px;
	font-weight: bold;
	text-align: center;
}

.form-label {
	font-size: 16px;
	font-weight: bold;
}

.btn-primary {
	background-color: #007bff;
	border-color: #007bff;
}

.btn-primary:hover {
	background-color: #0069d9;
	border-color: #0062cc;
}

.pointer {
	cursor: pointer;
}


.modal-title {
	font-size: 24px;
	font-weight: bold;
	text-align: center;
}

.form-label {
	font-size: 16px;
	font-weight: bold;
}

.btn-primary {
	background-color: #007bff;
	border-color: #007bff;
}

.btn-primary:hover {
	background-color: #0069d9;
	border-color: #0062cc;
}

.show {
	display: block !important;
}

.hide {
	display: none;
}

.form-group {
	padding: 0 1rem;
}

.navbar-search-block .input-group {
	width: 88%;
}

input[type="file"] {
	display: block;
}

.file {
	visibility: hidden;
	position: absolute;
}

.disable-button {
	pointer-events: none;
	opacity: 0.5;
}

.disable-nav {
	pointer-events: none;
}

.invalid {
	color: red;
	font-size: 12px;
}

.dILRuI {
	border: none;
	border-radius: 10px;
}

.item-img {
	border-radius: 5px
}

.items {
	cursor: pointer;
}

.select-content {
	padding-left: 35px;
}

.fileUploadModal {
	cursor: pointer;
}

.fileUploadModal a{
	text-decoration: underline !important;
}

.filesList {
	display: flex;
}

.status-img {
	height: 38px;
	width: 15px;
}

.password-icon {
	position: absolute;
	right: 2%;
	top: 16%;
	height: 20px;
}

.delete {
	height: 18px !important;
}

.upload-btn-wrapper {
	position: relative;
	overflow: hidden;
	display: inline-block;
}

.upload-btn-wrapper input[type=file] {
	font-size: 100px;
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
	line-height: 44px;
}

.required {
	color: rgba(241, 7, 7, 0.856);
}

.token-height {
	height: 18px !important;
}

.desc-modal {
	top: 200px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}

/* ======================== */
/*   payments css starts 	*/
/* ========================	*/
@import url(https://fonts.googleapis.com/css?family=Lato:400,100,100italic,300,300italic,400italic,700italic,700,900italic,900);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,100,200,300,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,100,200,300,500,600,700,800,900);
/* 
body {
	background-color: #eee !important;
} */

#generic_price_table {
	background-color: #f0eded;
}

/*PRICE COLOR CODE START*/
#generic_price_table .generic_content {
	background-color: #fff;
}

#generic_price_table .generic_content .generic_head_price {
	background-color: #f6f6f6;
}

#generic_price_table .generic_content .generic_head_price .generic_head_content .head_bg {
	border-color: #e4e4e4 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #e4e4e4;
}

#generic_price_table .generic_content .generic_head_price .generic_head_content .head span {
	color: #525252;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .sign {
	color: #414141;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .currency {
	color: #414141;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .cent {
	color: #414141;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .month {
	color: #414141;
}

#generic_price_table .generic_content .generic_feature_list ul li {
	color: #a7a7a7;
}

#generic_price_table .generic_content .generic_feature_list ul li span {
	color: #414141;
}

#generic_price_table .generic_content .generic_price_btn a {
	border: 1px solid #0d6efd;
	color: #0d6efd;
}

#generic_price_table .generic_content.active .generic_head_price .generic_head_content .head_bg,
#generic_price_table .generic_content:hover .generic_head_price .generic_head_content .head_bg {
	border-color: #0d6efd rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #0d6efd;
	color: #fff;
}

#generic_price_table .generic_content:hover .generic_head_price .generic_head_content .head span,
#generic_price_table .generic_content.active .generic_head_price .generic_head_content .head span {
	color: #fff;
}

#generic_price_table .generic_content:hover .generic_price_btn a,
#generic_price_table .generic_content.active .generic_price_btn a {
	background-color: #0d6efd;
	color: #fff;
}

#generic_price_table {
	margin: 50px 0 50px 0;
}

.row .table {
	padding: 28px 0;
}

/*PRICE BODY CODE START*/

#generic_price_table .generic_content {
	overflow: hidden;
	position: relative;
	text-align: center;
}

#generic_price_table .generic_content .generic_head_price {
	margin: 0 0 20px 0;
}

#generic_price_table .generic_content .generic_head_price .generic_head_content {
	margin: 0 0 50px 0;
}

#generic_price_table .generic_content .generic_head_price .generic_head_content .head_bg {
	border-style: solid;
	border-width: 90px 1411px 23px 399px;
	position: absolute;
}

#generic_price_table .generic_content .generic_head_price .generic_head_content .head {
	padding-top: 40px;
	position: relative;
}

#generic_price_table .generic_content .generic_head_price .generic_head_content .head span {
	font-family: "Raleway", sans-serif;
	font-size: 28px;
	font-weight: 400;
	letter-spacing: 2px;
	margin: 0;
	padding: 0;
	text-transform: uppercase;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag {
	padding: 0 0 20px;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price {
	display: block;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .sign {
	display: inline-block;
	font-size: 28px;
	font-weight: 400;
	vertical-align: middle;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .currency {
	font-size: 60px;
	font-weight: 300;
	letter-spacing: -2px;
	line-height: 60px;
	padding: 0;
	vertical-align: middle;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .cent {
	display: inline-block;
	font-size: 24px;
	font-weight: 400;
	vertical-align: bottom;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .month {
	font-size: 18px;
	font-weight: 400;
	letter-spacing: 3px;
	vertical-align: bottom;
}

#generic_price_table .generic_content .generic_feature_list ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

#generic_price_table .generic_content .generic_feature_list ul li {
	font-size: 18px;
	padding: 15px 0;
	transition: all 0.3s ease-in-out 0s;
}

#generic_price_table .generic_content .generic_feature_list ul li .fa {
	padding: 0 10px;
}

#generic_price_table .generic_content .generic_price_btn {
	margin: 20px 0 32px;
}

#generic_price_table .generic_content .generic_price_btn a {
	border-radius: 50px;
	-moz-border-radius: 50px;
	-ms-border-radius: 50px;
	-o-border-radius: 50px;
	-webkit-border-radius: 50px;
	display: inline-block;
	font-size: 18px;
	outline: medium none;
	padding: 12px 30px;
	text-decoration: none;
	text-transform: uppercase;
}

#generic_price_table .generic_content,
#generic_price_table .generic_content:hover,
#generic_price_table .generic_content .generic_head_price .generic_head_content .head_bg,
#generic_price_table .generic_content:hover .generic_head_price .generic_head_content .head_bg,
#generic_price_table .generic_content .generic_head_price .generic_head_content .head h2,
#generic_price_table .generic_content:hover .generic_head_price .generic_head_content .head h2,
#generic_price_table .generic_content .price,
#generic_price_table .generic_content:hover .price,
#generic_price_table .generic_content .generic_price_btn a,
#generic_price_table .generic_content:hover .generic_price_btn a {
	transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
}

@media (max-width: 320px) {}

@media (max-width: 767px) {
	#generic_price_table .generic_content {
		margin-bottom: 75px;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	#generic_price_table .col-md-3 {
		float: left;
		width: 50%;
	}

	#generic_price_table .col-md-4 {
		float: left;
		width: 50%;
	}

	#generic_price_table .generic_content {
		margin-bottom: 75px;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {}

@media (min-width: 1200px) {}

.text-center h1,
.text-center h1 a {
	color: #7885cb;
	font-size: 30px;
	font-weight: 300;
	text-decoration: none;
}

.demo-pic {
	margin: 0 auto;
}

.demo-pic:hover {
	opacity: 0.7;
}

#generic_price_table_home ul {
	margin: 0 auto;
	padding: 0;
	list-style: none;
	display: table;
}

#generic_price_table_home li {
	float: left;
}

#generic_price_table_home li+li {
	margin-left: 10px;
	padding-bottom: 10px;
}

#generic_price_table_home li a {
	display: block;
	width: 50px;
	height: 50px;
	font-size: 0px;
}

#generic_price_table_home .blue {
	background: #3498db;
	transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .emerald {
	background: #0d6efd;
	transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .grey {
	background: #7f8c8d;
	transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .midnight {
	background: #34495e;
	transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .orange {
	background: #e67e22;
	transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .purple {
	background: #9b59b6;
	transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .red {
	background: #e74c3c;
	transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .turquoise {
	background: #1abc9c;
	transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .blue:hover,
#generic_price_table_home .emerald:hover,
#generic_price_table_home .grey:hover,
#generic_price_table_home .midnight:hover,
#generic_price_table_home .orange:hover,
#generic_price_table_home .purple:hover,
#generic_price_table_home .red:hover,
#generic_price_table_home .turquoise:hover {
	border-bottom-left-radius: 50px;
	border-bottom-right-radius: 50px;
	border-top-left-radius: 50px;
	border-top-right-radius: 50px;
	transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .divider {
	border-bottom: 1px solid #ddd;
	margin-bottom: 20px;
	padding: 20px;
}

#generic_price_table_home .divider span {
	width: 100%;
	display: table;
	height: 2px;
	background: #ddd;
	margin: 50px auto;
	line-height: 2px;
}

#generic_price_table_home .itemname {
	text-align: center;
	font-size: 50px;
	padding: 50px 0 20px;
	border-bottom: 1px solid #ddd;
	margin-bottom: 40px;
	text-decoration: none;
	font-weight: 300;
}

#generic_price_table_home .itemnametext {
	text-align: center;
	font-size: 20px;
	padding-top: 5px;
	text-transform: uppercase;
	display: inline-block;
}

#generic_price_table_home .footer {
	padding: 40px 0;
}

.price-heading {
	text-align: center;
}

.price-heading h1 {
	color: #666;
	margin: 0;
	padding: 0 0 50px 0;
}

.demo-button {
	background-color: #333333;
	color: #ffffff;
	display: table;
	font-size: 20px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
	margin-bottom: 50px;
	outline-color: -moz-use-text-color;
	outline-style: none;
	outline-width: medium;
	padding: 10px;
	text-align: center;
	text-transform: uppercase;
}

.bottom_btn {
	background-color: #333333;
	color: #ffffff;
	display: table;
	font-size: 28px;
	margin: 60px auto 20px;
	padding: 10px 25px;
	text-align: center;
	text-transform: uppercase;
}

.demo-button:hover {
	background-color: #666;
	color: #fff;
	text-decoration: none;
}

.bottom_btn:hover {
	background-color: #666;
	color: #fff;
	text-decoration: none;
}

.center {
	display: flex;
	align-items: center;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 30em;
	height: 18em;
	margin-top: -9em;
	/*set to a negative number 1/2 of your height*/
	margin-left: -15em;
	/*set to a negative number 1/2 of your width*/
}

.site-result-demo-error-icon {
	color: red;
}

.img-fluid {
	max-height: 95% !important;
}

.table-content {
	border-collapse: collapse;
	width: 100%;
}

.table-th-td {
	border: 1px solid #dddddd;
	padding: 8px;
}

.image-content-row {
	border-bottom: 1px solid #ccc;
}

.item-image-radius {
	border-radius: 8px;
}

.file-attachments img {
	height: 25px;
	width: 50px;
}

table {
	table-layout: fixed;
}

td {
	word-wrap: break-word;
}

.page-over {
	display: none;
	z-index: 99;
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
}

.modal-confirmation {
	z-index: 999;
}

.tooltip-text {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.note-message {
	font-size: 12px;
	color: #8C8F90;
	text-decoration: none;
	padding: 0px 6px;
	border-radius: 10px;
}

.warning {
	color: red;
}

.badge {
	color: white;
	padding: 4px 8px;
	text-align: center;
	border-radius: 6px;
	padding: 10px 10px;
	font-size: 12px;
}

.active-plan {
	background-color: green;
}

.inactive-plan {
	background-color: red;
}

.plan-badge {
	display: flex;
	gap: 10px;
	align-items: end;
}

.modal-content-size {
	width: 83%;
}

.modal-content-font {
	font-size: inherit;
}

.plans-modal-table {
	margin: 10px 20px;
	border: 1px solid lightgray;
}

.edit-delete-btn {
	display: flex;
	gap: 5px;
}

.edit-delete-btn button {
	font-size: 9.7px;
	padding: 2px 4px;
}

.logged-in {
	color: green;
	font-size: 2.1em;
	cursor: pointer;
}

.logged-out {
	color: red;
	font-size: 2.1em;
	cursor: pointer;
}

.pending-out {
	color: rgb(255, 166, 0);
	font-size: 2.1em;
	cursor: pointer;
}

.font-weight-bold {
	font-weight: bold;
}

.parallel-list {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
}