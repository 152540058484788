/* #Font-Face
================================================== */
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;0,700;0,900;1,600;1,700;1,900&display=swap');
/* #Site Styles
================================================== */

/* ### general ### */
* {
	margin: 0px;
	padding: 0px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

html,
body {
	font-size: 12px;
}

input,
select,
textarea {
	font-size: 12px;
	color: #000;
	padding-left: 5px;
}

a{
	text-decoration: none;
}

ul,
li {
	list-style: none;
}

:focus {
	outline: none;
	box-shadow: none;
}

.form-select:focus {
	box-shadow: none;
}

/* ### global classes ### */
.clear {
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

.container {
	position: relative;
}

/* ### wrapper ### */
#wrapper {
	position: relative;
	display: block;
	width: 100%;
	min-height: 100%;
}

/* ### main container ### */
#mainCntr {
	width: 100%;
	overflow: hidden;
}

/* ### header container ### */
.header {
	height: 48px;
}

#headerCntr {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	z-index: 2;
	background: #fff;
}

#headerCntr .logo {
	float: left;
	padding: 3px 12px 3px 0;
}

#headerCntr .logo img {
	max-width: 100%;
	height: 45px;
}

#headerCntr .toggle_menu {
	font-size: 30px;
	color: #333;
	margin: 4px 12px 0 33px;
	display: none;
	float: left;
}

/* ### Menu box ### */
.menuBox {
	padding-top: 3px;
	width: auto;
	float: left;
}

.menuBox ul {
	list-style: none;
}

.menuBox li {
	padding-right: 10px;
	float: left;
	position: relative;
	display: flex;
	align-items: center;
}

.menuBox li a {
	position: relative;
	color: #8C8F90;
	display: flex;
	padding: 12px 15px 12px 0;
	font-size: 12px;
	font-weight: 600;
	text-decoration: none;
	align-items: center;
	gap: 10px;
}

.menuBox li a.active {
	color: #005DFF;
}

.menuBox li a.active:after {
	background: #005DFF;
}

.menuBox li img {
	margin-right: 10px;
}

/* ### headericon box ### */
.headericon {
	float: right;	
}

.headericon ul {
	list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
}

.btn-outline-success {
	position: absolute;
    right: 1%;
    top: 25%;
	background: #005DFF;
	border-radius: 8px;
	color: #FFFFFF;
	border: none;
}
.btn-outline-success:hover{
	background: #005DFF;
	border-radius: 8px;
	color: #FFFFFF;
	border: none;
}
.headericon li {
	float: left;
	padding: 12px 10px;
	position: relative;
}

.headericon li a {
	text-decoration: none;
}

.headericon li img {
	border-radius: 5px;
}

.breadcrumbs {
	padding-top: 15px;
    padding-left: 20px;
    float: left;
    color: #2A4153;
    font-weight: 600;
	background-size: 6px;
}

.breadcrumbs a {
	color: #2A4153;
	font-weight: 600;
	text-decoration: none;
}

.breadcrumb-item.active {
	color: #2A4153;
}

/* The inputSet */
.inputSet label {
	margin: 0px;
	cursor: pointer;
	font-weight: 500;
	font-size: 12px;
	padding-left: 25px;
	position: relative;
}

.inputSet {
	display: block;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	font-weight: 500;
}

.inputSet input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 15px;
	width: 15px;
	border: 1px solid #8C8F90;
	border-radius: 2px;
}

.inputSet input[type="checkbox"]:checked~.checkmark {
	border: 1px solid #4F8FFC;
}

.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

.inputSet input[type="checkbox"]:checked~.checkmark:after {
	display: block;
}

.inputSet .checkmark:after {
	left: 6px;
	top: 2px;
	width: 5px;
	height: 10px;
	border: solid #4F8FFC;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}


/*Radio*/
.inputSet label input[type="radio"]~span::after {
	border-radius: 100%;
	border: 2px solid #0C5DBA;
	content: "";
	height: 20px;
	width: 20px;
	left: 0;
	position: absolute;
	top: 0;
}

.inputSet label input[type="radio"]:checked~span {
	transition: all 0.5s;
}

.inputSet label input[type="radio"]:checked~span::after {
	border: 2px solid #0C5DBA;
}

.inputSet label input[type="radio"]:checked~span::before {
	border-radius: 100%;
	background: #0C5DBA;
	content: "";
	display: block;
	height: 10px;
	width: 10px;
	left: 5px;
	position: absolute;
	top: 5px;
}


/* ### content container ### */
#contentCntr {
	width: 100%;
	margin-top: 0px;
	padding: 15px 15px;
	position: relative;
}

/* ### search ### */
.searchBox {
	overflow: hidden;
	margin-bottom: 16px;
}

.searchBox .search {
	width: calc(100% - 92px);
	float: left;
	position: relative;
}

.searchBox .inputText {
	border: none;
	padding: 8px 10px 8px 50px;
	font-size: 13px;
	font-weight: 400;
	border-radius: 8px;
}

.searchBox .search_icon {
	border: none;
	background: none;
	position: absolute;
	left: 13px;
	top: 8px;
}

.searchBox .filter_btn {
	width: 85px;
	font-size: 12px;
	text-decoration: none;
	display: flex;
	font-weight: 600;
	float: right;
	background: #fff;
	padding: 8px 5px;
	border-radius: 8px;
	cursor: pointer;
	gap: 10px;
}

.searchBox .filter_btn img {
	margin-right: 10px;
	height: 18px;
    width: 18px;
}

/*action Row */
.actionRow {
	overflow: hidden;
	margin-bottom: 15px;
	transition: all 0.5s;
	display: flex;
    justify-content: space-between;
}

.actionRow .left {
	width: calc(100% - 165px);
	float: left;
	display: flex;
	align-items: center;
}

.actionRow .right {
	width: 130px;
	float: left;
}

.actionRow .addButton {
	margin-right: 20px;
	float: left;
	background: #005DFF;
	border-radius: 8px;
	font-size: 12px;
	font-weight: 600;
	padding: 0px 12px;
	height: auto;
	border: none;
	color: #fff;
	display: flex;
	align-items: center;
}

.actionRow .addButton i {
	font-size: 21px;
	position: relative;
	margin: 0 10px 0 0;
}

.actionRow a {
	font-size: 16px;
	color: #005DFF;
	text-decoration: none;
	font-weight: 600;
}

.actionRow img {
	float: left;
	margin: 0 10px 0 0;
}

.actionRow .form-select {
	background-color: #F1F1F9;
	border: none;
	padding: 7px 10px;
    font-size: 12px;
	font-weight: 600;
	color: #2A4153;
	border-radius: 8px;
}

.actionRow .form-select option {
	background: #fff;
}

.actionRow .title {
	font-size: 16px;
	color: #000000;
	font-weight: 600;
	display: flex;
	gap: 10px;
}

/*tabfilter*/
.tabfilter {
	width: 100%;
	overflow: hidden;
	border-top: 1px solid #ccc;
	padding: 24px 0;
}

.tabfilter ul {
	list-style: none;
	padding: 0px;
}

.tabfilter li {
	float: left;
	margin-right: 10px;
}

.tabfilter li a {
	font-size: 12px;
	color: #8C8F90;
	text-decoration: none;
	padding: 8px 16px;
	border-radius: 10px;
}

.tabfilter li a.active,
.tabfilter li a:hover {
	background: #F1F1F9;
	color: #005DFF;
}

.tabfilter.filter2 {
	height: 2% !important;
	overflow-y: hidden !important;
	border-top: none;
	padding: 10px 0 35px 0;
	margin-top: -10px;
}


/*Box table */
.box {
	background: #fff;
	padding: 24px;
	border-radius: 20px;
}

.table_structure .checkinfo {
	display: flex;
	align-items: center;
}

.table_structure .checkinfo img {
	border-radius: 10px;
	margin: 0 10px;
}

.table_structure .right_arrow {
	float: right;
	height: 20px;
	width: 20px;
}

.table_structure .checkmark {
	top: -7px;
}

.table_structure th:last-child {
	border-radius: 0 10px 10px 0px;
}

.table_structure td {
	vertical-align: middle;
	font-weight: 700;
	white-space: nowrap;
}

.table_structure th,
.table_structure td {
	padding: 13px 10px;
}

.table_structure tr:hover {
	background: #F1F1F9;
	border-radius: 10px;
}

.table_structure tr:hover td:first-child {
	border-radius: 10px 0 0px 10px;
}

.table_structure tr:hover td:last-child {
	border-radius: 0 10px 10px 0px;
}

.table_structure .discription {
	width: 114px;
	float: left;
	font-weight: 400;
	overflow: hidden;
	text-overflow: ellipsis;
}

.watchestable th:first-child,
.watchestable td:first-child {
	padding-left: 60px;
}

.watchestable th,
.watchestable td {
	width: 25%;
}

.watches2table th,
.watches2table td {
	height: 53px;
}

.watches2table th:first-child {
	padding-left: 10px;
}


.tokentable th:first-child {
	padding-left: 10px;
}

.tokentable2 th:first-child {
	padding-left: 90px;
	position: relative;
}

.tokentable2 .img {
	float: left;
	border-radius: 10px;
	margin-right: 10px;
}

.tokentable2 .discription {
	width: 150px;
}

.inputSet label.thlabel {
	position: absolute;
	left: 14px;
	top: 21px;
}

/*Box table */

/**/
.paginationBox {
	padding: 10px 0;
	overflow: hidden;
	border-top: 1px solid #8C8F90;
	border-bottom: 1px solid #8C8F90;
}

.paginationBox .left {
	float: left;
}

.paginationBox .right {
	float: right;
	width: 232px;
	text-align: right;
}

.paginationBox .right .form-select {
	background-color: #F1F1F9;
	border: none;
	padding: 3px 10px;
	font-size: 12px;
	font-weight: 600;
	color: #2A4153;
	border-radius: 8px;
	width: 50px;
	float: left;
}

.paginationBox .perpage {
	padding: 0px 20px 0 10px;
}

.paginationList {
	padding: 0px;
	margin: 0px;
	list-style: none;
	display: flex;
}

.paginationList li {
	display: flex;
}

.paginationList li a {
	width: 30px;
	text-align: center;
	font-size: 12px;
	font-weight: 600;
	text-decoration: none;
	color: #2A4153;
	display: block;
}

.paginationList li img {
	display: inline-block;
}

/**/
.searchFilter {
	position: absolute;
	top: 50px;
	right: 0;
    left: 0;
	margin-left: auto;
	margin-right: auto;
	width: 80%;
	z-index: 3;
	display: none;
	background: #fff;
	padding: 24px;
	border-radius: 8px;
	overflow: hidden;
	box-shadow: 0 0 20px rgba(34, 102, 247, 0.29);
	margin-bottom: 18px;
}

.searchFilter .holder {
	margin-right: -10px;
}

.searchFilter .column {
	width: calc(20% - 10px);
	margin-right: 10px;
	float: left;
	position: relative;
}

.searchFilter .inputText2 {
	margin-bottom: 20px;
	border: 1px solid #8C8F90;
	border-radius: 8px;
	padding: 8px 8px 8px 40px;
	font-size: 12px;
	font-weight: 400;
	color: #000;
	width: 100%;
}

.searchFilter .icon {
	position: absolute;
	left: 12px;
	top: 8px;
	height: 18px;
}

.searchFilter .applyButton {
	background: #005DFF;
    border-radius: 8px;
    border: none;
    width: 90px;
    height: 30px;
    color: #fff;
    font-size: 12px;
    float: right;
    font-weight: 600;
}

.searchFilter .checklist {
	margin: 0px;
	padding: 10px 0 0 0;
	list-style: none;
}

.searchFilter .checklist li {
	font-size: 14px;
	color: #2A4153;
	float: left;
	padding-right: 30px;
}

.searchFilter.watchfilter {
	width: 60%;
}

.searchFilter.watchfilter .column {
	width: calc(33.3% - 10px);
}

.pageoverlay {
	display: none;
	z-index: 2;
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
}

.pageoverlay-model {
	display: none;
	z-index: 2;
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
}

/*admin top*/
.watchesCategory {
	padding: 10px 0;
	width: 100%;
	background: #fff;
	overflow: hidden;
	border-radius: 0 0px 50px 50px;
}

.watchesCategory .left {
	padding-top: 25px;
	width: 165px;
	padding-right: 40px;
	float: left;
}

.watchesCategory .left img {
	border-radius: 15px;
	width: 100%;
}

.watchesCategory .right {
	width: calc(100% - 200px);
	float: left;
}

.watchesCategory .topRow ul {
	padding: 0 0 10px 0px;
	margin: 0px;
	overflow: hidden;
}

.watchesCategory .topRow li {
	width: 20%;
	float: left;
	color: #8C8F90;
	padding: 5px;
}

.watchesCategory .topRow li .text {
	display: flex;
	align-items: center;
	font-size: 12px;
}

.watchesCategory .topRow li img {
	margin-right: 8px;
}

.watchesCategory .topRow li strong {
	font-size: 16px !important;
	color: #2A4153;
	font-weight: 600;
	word-wrap: break-word;
}

.watchesCategory .bottomRow {
	overflow: hidden;
	padding:10px 0 0 0;
	border-top: 1px solid #ccc;
}

.watchesCategory .bottomRow .holder {
	/* margin-right: -40px; */
}

.watchesCategory .bottomRow .column1 {
	padding-right: 20px;
	width: 33.3%;
	float: left;
}

.watchesCategory .bottomRow .column1.column2 {
	padding: 5px 40px 0 40px;
}

.watchesCategory .bottomRow .column1.column3 {
	padding: 0px 20px 0 20px;
}

.watchesCategory .content {
	font-size: 12px;
	color: #2A4153;
	line-height: 20px;
}

.watchesCategory .title {
	padding-bottom: 10px;
	font-size: 12px;
	color: #8C8F90;
}

.attchedfile {
	max-width: 350px;
    overflow-x: scroll;
	padding: 0px;
}

.attchedfile li {
	margin-bottom: 10px;
}

.attchedfile li img {
	float: left;
	margin-right: 10px;
}

/*Watches 2*/
/*token detail */
.tokenDetail {
	width: 100%;
	display: flex;
}

.tokenDetail .leftdetail {
	width: calc(100% - 476px);
	float: left;
	transition: all 0.5s;
}

.tokenDetail .leftdetail.expend {
	width: 100%;
	transition: all 0.5s;
}

.tokenDetail .rightdetail.righthide {
	display: none;
}

.actionRow.actionhide {
	display: none;
}

.transferaction.transferactionhide {
	display: none;
}


.tokenDetail .rightdetail {
	margin-left: 10px;
	width: calc(476px - 10px);
	float: left;
	background: #fff;
	padding: 12px 24px;
	border-radius: 20px;
	overflow: hidden;
	height:509px;
}

.tokenDetail .rightdetail .title {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 16px;
	color: #005DFF;
	font-weight: 600;
	border-bottom: 1px solid #C5D2D7;
	padding: 0 0 10px 0;
}

.tokenDetail .rightdetail .title img {
	margin-right: 10px;
}

.tokenDetail .rightdetail .title div {
	display: flex;
	gap: 10px;
}

.tokenDetail .rightdetail .title .icons {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.tokenDetail .rightdetail .title .icons img {
	margin-right: 0px;
	margin-left: 10px;
}

.tokenDetail .rightdetail .detail {
	border-bottom: 1px solid #C5D2D7;
	padding: 0 0 10px 0;
	overflow: hidden;
}

.tokenDetail .rightdetail .detail.noborder {
	border-bottom: none;
}

.tokenDetail .rightdetail .detail .row1 {
	padding: 10px 0;
	display: flex;
}

.tokenDetail .rightdetail .detail strong {
	width: 100px;
}

.tokenDetail .rightdetail .details {
	width: calc(100% - 100px);
	overflow: hidden;
}

.tokenDetail .rightdetail .addTitle {
	padding: 15px 0;
	font-size: 18px;
	font-weight: 600;
	color: #2A4153;
	display: flex;
}

.tokenDetail .rightdetail .addTitle img {
	margin-right: 10px;
}

.tokenDetail .rightdetail .label {
	display: block;
	font-weight: 600;
}

.tokenDetail .rightdetail .textarea {
	padding: 10px;
	width: 100%;
	height: 350px;
	border: 1px solid #bebebe;
	border-radius: 8px;
	font-size: 14px;
	color: #6F778B;
}

.tokenDetail .savebutton {
	margin-top: 10px;
	background: #C5D2D7;
	border-radius: 8px;
	padding: 8px 12px;
	display: flex;
	border: none;
	font-size: 16px;
	color: #fff;
	font-weight: 600;
}

.tokenDetail .savebutton img {
	margin-right: 10px;
}

.tokenDetail .transferbtn {
	/* position: absolute; */
	right: 36px;
	bottom: 30px;
	border: none;
	float: right;
	margin-top: 10px;
	padding: 8px 16px;
	background: #005DFF;
	font-size: 12px;
	color: #fff;
	font-weight: 600;
	border-radius: 8px;
	text-decoration: none;
}

.tokenDetail .addnoteBtn {
	background: #F1F1F9;
	border: none;
	float: right;
	padding: 5px 16px;
	font-size: 11px;
	color: #005DFF;
	font-weight: 600;
	border-radius: 8px;
	text-decoration: none;
	width: 100%;
	display: flex;
	text-align: center;
	justify-content: center;
	gap: 10px;
}

.tokenDetail .addnoteBtn img {
	margin-right: 10px;
}

/*Watches 2*/
.transferaction {
	transition: all 0.5s;
	margin-bottom: 20px;
	width: 100%;
	display: flex;
	align-items: center;
	padding: 8px 0;
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
}

.transferaction .selectClick {
	cursor: pointer;
	padding: 8px 63px 8px 10px;
	display: flex;
	border-radius: 8px;
	color: #fff;
	background: #005DFF url(../images/arrow-white.svg) 93% center no-repeat;
	background-size: 14px;
}

.custom_select {
	position: relative;
}

.custom_select ul {
	display: none;
	position: absolute;
	left: 0px;
	top: 40px;
	background: #fff;
	padding: 0px 0;
	border-radius: 8px;
	overflow: hidden;
	box-shadow: 0 0 20px rgba(34, 102, 247, 0.29);
	width: 100%;
	z-index: 2;
}

.custom_select li {
	display: flex;
	width: 100%;
	color: #2A4153;
	padding: 7px 15px;
	cursor: pointer;
}

.custom_select li:hover {
	color: #005DFF;
}

.transferaction img {
	margin-right: 10px;
}

.transferaction .items {
	display: flex;
	float: left;
	white-space: nowrap;
	padding-right: 22px;
	font-size: 12px;
	font-weight: 600;
	color: #005DFF;
}

/*Watches 3*/
.notoken {
	padding: 50px 0;
	width: 100%;
	text-align: center;
}

.notoken h3 {
	font-size: 35px;
	color: #8C8F90;
	font-weight: 400;
	padding-bottom: 20px;
}

.notoken .createButton {
	position: absolute;
	left: 50%;
	margin-top: 10px;
	background: #005DFF;
	border-radius: 8px;
	padding: 8px 12px;
	display: flex;
	border: none;
	font-size: 16px;
	color: #fff;
	font-weight: 600;
	transform: translateX(-50%);
	margin-top: -80px;
}

.notoken img {
	display: inline-block;
}

/*Token 3*/
.tokenCategory {
	padding: 40px 0 10px 0;
    width: 100%;
    background: #fff;
    overflow: hidden;
    border-radius: 0 0px 50px 50px;
}

.tokenCategory .left {
	width: 75%;
	float: left;
}

.tokenCategory .right {
	width: 25%;
	float: left;
	text-align: center;
	position: relative;
	margin-top: -25px;
}

.tokenCategory ul {
	padding: 0px;
	margin-right: -30px;
}

.tokenCategory li {
	margin-right: 30px;
    width: calc(33.3% - 30px);
    float: left;
    font-size: 12px;
    color: #8C8F90;
    font-weight: 600;
    padding: 12px 12px 12px 12px;
    border: 1px solid #C5D2D7;
    border-radius: 13px;
}

.tokenCategory li img {
	margin-right: 10px;
	float: left;
}

.tokenCategory li strong {
	font-size: 18px;
    color: #061340;
    font-weight: 700;
    display: block;
    line-height: 20px;
}

.tokenCategory .createButton {
	/* display: flex;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	margin-top: -50px;
	margin-top: 10px; */
	background: #005DFF;
	border-radius: 8px;
	padding: 8px 12px;
	display: flex;
	border: none;
	font-size: 12px;
	color: #fff;
	font-weight: 600;
}

.tokenCategory .createButton img {
	margin-right: 10px;
}

.tokenCategory .right .image {
	width: 100px;
	display: inline-block;
}
.tokenCategory .tokens-compo-div .card {
    height: 120px;
	/* width: 220px; */
}
.tokenCategory .tokens-compo-div .card-border{
	padding: 20px;
}

.tokenCategory .tokens-compo-div .card img{
	height: 30px;
	width: 30px;
}
.image-coin{
	width: 100px !important;
	display: inline-block;
}

/* popup box*/
.popup_Box {
	background-color: rgba(0, 0, 0, 0.75);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	overflow-y: auto;
}

.popup_Box .center-block {
	width: 100%;
	height: 100%;
	z-index: 1001;
	display: table;
	table-layout: fixed;
}

.popup_Box .outer {
	display: table-cell;
	vertical-align: middle;
	width: 100%;
	height: 100%;
	padding: 40px 0;
}

.popupBg {
	max-width: 690px;
	background: #FFFFFF;
	margin: 0px auto;
	border-radius: 20px;
	padding: 24px;
	overflow: hidden;
	position: relative;
}

.popupBg .left {
	width: 200px;
	float: left;
}

.popupBg .left img {
	border-radius: 15px;
}

.popupBg .right {
	width: calc(100% - 200px);
	float: left;
}

.poptitle {
	font-size: 18px;
	font-weight: 600;
	display: flex;
	padding-bottom: 5px;
}

.poptitle img {
	margin-right: 10px;
}

.form .inputText2 {
	margin-bottom: 15px;
	border: 1px solid #8C8F90;
	border-radius: 8px;
	padding: 8px 8px 8px 40px;
	font-size: 14px;
	font-weight: 400;
	color: #000;
	width: 100%;
}

.form .icon {
	position: absolute;
	left: 8px;
	top: 33px;
}

.form label {
	display: block;
	padding-bottom: 5px;
}

.form .buttons {
	text-align: right;
}

.addNote-btns{
	position: absolute;
    right: 3%;
}

.form .applyButton {
	padding: 6px 20px;
	margin-left: 10px;
	background: #fff;
	color: #005DFF;
	border-radius: 8px;
	border: 1px solid #005DFF;
	font-size: 12px;
}

.form .applyButton.active {
	background: #005DFF;
	color: #fff;
}

.form .column {
	position: relative;
}

.open-model {
	overflow: hidden;
}

/*Responsive*/
@media only screen and (max-width:1319px) {
	#contentCntr {
		padding: 15px 0;
	}

	.container {
		width: 100%;
		max-width: 100%;
	}

	.watchesCategory .content {
		font-size: 14px;
	}

	.watchesCategory .bottomRow .column1.column2 {
		padding: 0 30px;
	}

	.slick-next {
		right: -30px;
	}

	.watchesCategory .bottomRow .column1.column3 {
		padding-left: 95px;
	}

	.tokenDetail .leftdetail {
		width: calc(100% - 376px);
	}

	.tokenDetail .rightdetail {
		width: calc(376px - 10px);
	}

	.transferaction .items {
		font-size: 14px;
		padding-right: 20px;
	}

	.tokenCategory li {
		font-size: 18px;
		padding: 20px;
	}

	.box,
	.tokenDetail .rightdetail {
		padding: 15px;
	}

}

@media only screen and (max-width:1023px) {
	.menuBox ul {
		padding: 0px;
	}

	#contentCntr {
		padding-left: 0px;
		padding-right: 0px;
	}

	.table_structure th {
		padding-right: 25px;
	}

	.searchFilter {
		width: 97%;
		padding: 15px 10px;
	}

	.watchesCategory .left {
		padding-right: 20px;
		width: 150px;
	}

	.watchesCategory .right {
		width: calc(100% - 150px);
	}

	.watchesCategory .topRow li strong {
		font-size: 20px;
	}

	.watchesCategory .bottomRow .column1.column3 {
		padding-left: 60px;
	}

	.slider .item {
		text-align: center;
	}

	.slider .slick-slide img {
		max-width: 100%;
		display: inline-block;
	}

	.searchFilter.watchfilter {
		width: 80%;
		right: 15px;
	}

	.tokenDetail {
		flex-direction: column;
	}

	.tokenDetail .leftdetail {
		width: 100%;
	}

	.tokenDetail .rightdetail {
		border-radius: 0;
		display: block;
		width: 100%;
		margin-left: 0px;
		margin-top: 20px;
		padding: 15px;
		height: 100%;
		position: fixed;
		right: -200%;
		top: 40px;
		transition: all 0.5s;
	}

	.tokenDetail .rightdetail.righthide {
		display: block;
		right: 0px;
		transition: all 0.5s;
	}

	.tokenDetail .rightdetail.righthide .holder {
		height: 100%;
		overflow-y: auto;
		padding-bottom: 120px;
	}

	.tokenDetail .rightdetail .textarea {
		height: 250px;
	}

	.tokenCategory .createButton {
		font-size: 14px;
		white-space: nowrap;
	}

	.tokenCategory ul {
		margin-right: -15px;
		overflow: hidden;
	}

	.tokenCategory li {
		margin-right: 15px;
		width: calc(33.3% - 15px);
		font-size: 14px;
		padding: 14px;
	}

	.tokenCategory li strong {
		font-size: 26px;
	}

	.tokenDetail .transferbtn {
		position: static;
	}

	.tokenDetail .savebutton {
		float: left;
	}

	.mobmodel {
		overflow: hidden;
	}

}

@media only screen and (max-width:767px) {

	html,
	body {
		font-size: 13px;
	}

	.transferaction {
		white-space: nowrap;
		overflow-x: auto;
		overflow-y: hidden;
	}

	.menuBox {
		display: none;
		position: absolute;
		left: 0px;
		top: 51px;
		background: #fff;
	}

	.menuBox li {
		width: 100%;
	}

	.actionRow .addButton i {
		font-size: 18px;
	}

	.actionRow .addButton {
		font-size: 14px;
		padding: 3px 9px;
		margin-right: 10px;
	}

	.actionRow a {
		font-size: 14px;
	}

	.actionRow .form-select {
		font-size: 14px;
	}

	.actionRow .right {
		width: 150px;
		float: right;
	}

	.paginationBox .left {
		width: 100%;
	}

	.paginationBox .right {
		width: 100%;
		padding-top: 5px;
		text-align: left;
	}

	.searchFilter {
		width: 460px;
		left: 50%;
		transform: translateX(-50%);
	}

	.searchFilter .holder {
		margin: 0px;
	}

	.searchFilter .column {
		width: 100%;
	}

	.searchFilter .checklist {
		padding: 0 0 10px 0;
	}

	#headerCntr .toggle_menu {
		display: block;
		margin: 11px 12px 0 -12px;
	}

	.watchesCategory {
		position: relative;
		padding: 40px 0 20px 0;
		border-radius: 0 0px 20px 20px;
	}

	.breadcrumbs {
		position: absolute;
		left: 20px;
		top: -54px;
	}

	.watchesCategory .left {
		padding-top: 10px;
		width: 150px;
		margin: 0px auto;
		float: none;
		padding-right: 0px;
	}

	.watchesCategory .right {
		width: 100%;
	}

	.watchesCategory .topRow ul {
		display: flex;
		overflow-x: auto;
		padding: 15px 0;
	}

	.watchesCategory .topRow li {
		width: auto;
		padding-right: 30px;
		white-space: nowrap;
	}

	.watchesCategory .bottomRow .holder {
		margin: 0px;
	}

	.watchesCategory .bottomRow .column1 {
		width: 100%;
		padding-right: 0px;
	}

	.watchesCategory .bottomRow .column1.column2 {
		padding: 0px;
	}

	.watchesCategory .bottomRow .slider {
		padding: 0 40px;
	}

	.watchesCategory .bottomRow .slider .slick-prev {
		left: 0px;
	}

	.watchesCategory .bottomRow .slider .slick-next {
		right: 0px;
	}

	.watchesCategory .bottomRow .column1.column3 {
		padding-top: 20px;
		padding-left: 0px;
	}

	.watchestable th:first-child,
	.watchestable td:first-child {
		padding-left: 15px;
	}

	/*Table */
	.watchestable th:last-child,
	.watchestable td:last-child {
		position: relative;
		padding-right: 30px;
	}

	.table_structure .right_arrow {
		position: absolute;
		right: 6px;
		top: 10px;
	}

	.watches2table th:last-child,
	.watches2table td:last-child,
	.tokentable th:last-child,
	.tokentable td:last-child,
	.tokentable2 th:last-child,
	.tokentable2 td:last-child {
		position: relative;
		padding-right: 30px;
	}

	.table_structure .tokentable th,
	.table_structure .tokentable td {
		padding: 8px 10px;
	}

	.table_structure .tokentable .right_arrow {
		position: absolute;
		right: 6px;
		top: 14px;
	}

	/*Table */
	.tabfilter.filter2 {
		padding: 0px;
	}

	.tabfilter.filter2 ul {
		margin-bottom: 0px;
	}

	.table-responsive {
		margin: 0 -15px;
	}

	.searchBox .inputText {
		padding-top: 10px;
		padding-bottom: 10px;
		font-size: 14px;
	}

	.tabfilter {
		padding: 0;
		margin: 0px;
	}

	.tabfilter ul {
		padding: 15px 0;
		margin: 0px;
		white-space: nowrap;
		overflow-x: auto;
		overflow-y: hidden;
		display: flex;
	}

	.tabfilter li a {
		font-size: 14px;
	}

	.statuscheck {
		width: 18px;
	}

	.actionRow .title {
		font-size: 18px;
	}

	.box {
		border-radius: 10px;
	}

	.actionRow .left.token_title {
		width: auto;
	}

	.searchFilter.watchfilter .column {
		width: 100%;
	}

	.notoken .createButton {
		font-size: 14px;
		white-space: nowrap;
	}

	.notoken img {
		width: 180px;
	}

	.tokenCategory .left {
		width: 100%;
	}

	.tokenCategory .right {
		width: 100%;
		margin: 0px;
	}

	.tokenCategory {
		padding: 13px 0;
	}

	.tokenCategory ul {
		margin-right: -10px;
		overflow: hidden;
	}

	.tokenCategory li {
		margin-right: 10px;
		width: calc(33.3% - 10px);
		font-size: 13px;
		padding: 10px 7px 3px 7px;
	}

	.tokenCategory li strong {
		font-size: 22px;
	}

	.tokenCategory li img {
		width: 25px;
		margin-right: 8px;
	}

	.tokenCategory {
		position: relative;
		padding: 40px 0 20px 0;
		border-radius: 0 0px 20px 20px;
	}

	.popupBg {
		width: 95%;
		max-width: inherit;
		padding: 15px;
	}

	.popupBg .left {
		width: 150px;
		padding-right: 20px;
	}

	.popupBg .left img {
		width: 100%;
	}

	.popupBg .right {
		width: calc(100% - 150px);
	}
}

@media only screen and (max-width:479px) {

	html,
	body {
		font-size: 12px;
	}

	#headerCntr .logo {
		width: 100px;
		
	}

	.headericon ul {
		padding: 0px;
	}

	.headericon li {
		padding: 15px 7px;
	}

	.headericon li img {
		width: 20px;
	}

	.actionRow .left {
		width: 100%;
		margin-bottom: 10px;
	}

	.box {
		font-size: 13px;
	}

	.table_structure .checkinfo img {
		width: 25px;
	}

	.searchFilter {
		width: 300px;
	}

	#headerCntr .toggle_menu {
		display: block;
		margin: 4px 12px 0 -12px;
	}

	.watchesCategory .topRow li img {
		width: 18px;
	}

	.watchesCategory .topRow li strong {
		font-size: 18px;
	}

	.watchesCategory .content {
		font-size: 13px;
	}

	.searchFilter.watchfilter {
		width: 95%;
	}

	.tokenDetail .transferbtn {
		bottom: 15px;
		right: 23px;
		font-size: 13px;
		padding: 8px 10px;
		height: 40px;
	}

	.tokenDetail .savebutton {
		font-size: 13px;
		height: 40px;
	}

	.notoken h3 {
		font-size: 26px;
	}

	.tokenCategory ul {
		margin: 0px;
	}

	.tokenCategory li {
		width: 100%;
		margin-left: 0px;
		margin-bottom: 10px;
	}

	.tokenDetail .rightdetail .detail .row1 {
		flex-direction: column;
	}

	.tokenDetail .rightdetail .detail strong {
		width: 100%;
		display: block;
		padding-bottom: 10px;
	}

	.tokenDetail .rightdetail .details {
		width: 100%;
	}

	.popupBg .left {
		float: none;
		margin: 0px auto;
	}

	.popupBg .right {
		width: 100%;
		padding-top: 20px;
	}
}


.no-record-div{
	/* font-family: 'Source Sans Pro'; */
	font-size: 12px;
    /* position: absolute; */
    top: 50%;
    left: 50%;
    /* transform: translate(-50%, -50%); */
    padding: 20px;
	background-color: lightgray;
	border-radius: 8px;
	color: gray;
}

.coin-btns{
	top: 65%;
}

.line-break{
	line-break: anywhere;
}

.image-content-row li {
	font-size: 11px;
}


.buy-token-btn{
	font-weight: 500 !important;
}