* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.background {
    background: white;
}

p {
    font-size: 12px;
}

li {
    font-size: 12px;
}

.listing ol li {
    font-size: 12px;
    margin-left: 20px;
}

.first-para {
    margin-left: 30px;
}