:root {
   --blue: #005DFF;
}

label {
   font-size: 1rem;
   padding-right: 10px;
   color: #666666;
}

select {
   font-size: 0.9rem;
   padding: 2px 5px;
}

p {
   margin-bottom: 0 !important;
}